@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

*{
  font-family: "Reddit Sans", sans-serif;
}
.app-container{
  margin-top: 70px;
  min-height: calc(100vh - 100px);
}
svg{
  cursor: pointer;
}
input:disabled{
  opacity: 0.7;
  background-color: #CFD8DC;
}

.c-height{
  min-height: calc(100vh - 160px);
}
.chat-height{
  min-height: calc(100vh - 195px);
  max-height: calc(100vh - 195px);
  overflow-y: scroll;
}

.modal-open {
  opacity: 0;
  animation: modalFadeIn 0.3s forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-close {
  opacity: 1;
  animation: modalFadeOut 0.3s forwards;
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.app-main{
  max-width: 2500px;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 450px){
  .chat-height{
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
  }
}
